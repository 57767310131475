<template>
  <div v-loading="showLoading">
    <div class="type-box" v-if="searchPage">
      <el-menu
        :default-active="typeBtnIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="changeTypeBtn"
        active-text-color="#47A2FF"
      >
        <el-submenu index="1">
          <template slot="title">审核档案</template>
          <el-submenu index="01">
            <template slot="title">年度内审</template>
            <el-menu-item index="0">股份公司年度度审核</el-menu-item>
            <el-menu-item index="1">子公司年度审核</el-menu-item>
          </el-submenu>
          <el-submenu index="02">
            <template slot="title">季度内审</template>
            <el-menu-item index="2">股份公司季度度审核</el-menu-item>
            <el-menu-item index="3">子公司季度审核</el-menu-item>
          </el-submenu>
        </el-submenu>
        <el-submenu index="CJ">
          <template slot="title">自查自纠</template>
          <el-menu-item index="ZC">自查</el-menu-item>
          <el-menu-item index="ZJ">自纠</el-menu-item>
        </el-submenu>
        <el-menu-item index="4">文件履历</el-menu-item>
        <el-submenu index="2">
          <template slot="title">学习档案</template>
          <el-submenu index="2-1">
            <template slot="title">指派档案</template>
            <el-menu-item index="6-1">专题指派</el-menu-item>
            <el-menu-item index="6-2">学习指派</el-menu-item>
          </el-submenu>
          <el-submenu index="2-2">
            <template slot="title">自主学习档案</template>
            <el-menu-item index="8">专题学习档案</el-menu-item>
            <el-menu-item index="7">文件学习档案</el-menu-item>
          </el-submenu>
        </el-submenu>
        <el-menu-item index="9">安全档案</el-menu-item>
        <!-- 以前的现场管理就是现在的7s管理 -->
        <el-menu-item index="5">7s管理</el-menu-item>
        <el-menu-item index="10">考核细则</el-menu-item>
        <!-- <el-menu-item index="12">风控检查</el-menu-item> -->
        <el-submenu index="11">
          <template slot="title">考试信息</template>
          <el-menu-item index="11-1">指派考试</el-menu-item>
          <el-menu-item index="11-2">审核考试</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <div class="type-box" v-if="searchPage">
      <div class="flex-box" v-if="typeBtnIndex != '4'">
        <div
          class="_Select"
          v-if="
            typeBtnIndex != '6-1' &&
            typeBtnIndex != '6-2' &&
            typeBtnIndex != '7' &&
            typeBtnIndex != '8' &&
            typeBtnIndex != '11-1' &&
            typeBtnIndex != '11-2'
          "
        >
          <span>年份：</span>
          <el-date-picker
            v-model="year"
            format="yyyy"
            value-format="yyyy"
            type="year"
            placeholder="选择年"
          >
          </el-date-picker>
        </div>
        <div
          class="_Select"
          v-if="(typeBtnIndex == 'ZC' || typeBtnIndex == 'ZJ') && !isManage"
        >
          <span>审核类型：</span>
          <el-select
            v-model="auditType"
            placeholder="请选择"
            class="Company_choose"
          >
            <el-option
              v-for="item in ['内审', '外审']"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div
          class="_Select"
          v-if="
            showFatherPage &&
            typeBtnIndex != '6-1' &&
            typeBtnIndex != '6-2' &&
            typeBtnIndex != '7' &&
            typeBtnIndex != '8' &&
            typeBtnIndex != '10' &&
            !isManage
          "
        >
          <span>公司：</span>
          <el-select
            v-model="compy"
            clearable
            placeholder="请选择"
            @change="changCompy"
          >
            <el-option
              v-for="item in $store.state.companyUserList"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div
          class="_Select"
          v-if="
            (typeBtnIndex != 'ZC' &&
              typeBtnIndex != 'ZJ' &&
              typeBtnIndex != '10' &&
              typeBtnIndex != '11-1' &&
              typeBtnIndex != '11-2' &&
              !showFatherPage &&
              !isManage) ||
            ((typeBtnIndex == '11-1' || typeBtnIndex == '11-2') &&
              showFatherPage)
          "
        >
          <span>部门：</span>
          <el-select
            v-model="dept"
            clearable
            placeholder="请选择"
            @change="changDept"
          >
            <el-option
              v-for="item in deptList"
              :key="item.id"
              :label="item.deptmentName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div
          class="_Select"
          v-if="
            (typeBtnIndex != 'ZC' &&
              typeBtnIndex != 'ZJ' &&
              isManage == false &&
              typeBtnIndex != '10' &&
              typeBtnIndex != '11-1' &&
              typeBtnIndex != '11-2' &&
              !showFatherPage &&
              !isManage) ||
            ((typeBtnIndex == '11-1' || typeBtnIndex == '11-2') &&
              showFatherPage)
          "
        >
          <span>岗位：</span>
          <el-select
            v-model="post"
            clearable
            placeholder="请选择"
            class="Company_choose"
          >
            <el-option
              v-for="item in postList"
              :key="item.value"
              :label="item.postName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div
          class="select-time"
          v-if="
            ((typeBtnIndex == '6-1' ||
              typeBtnIndex == '6-2' ||
              typeBtnIndex == '7' ||
              typeBtnIndex == '8') &&
              !isManage) ||
            ((typeBtnIndex == '11-1' || typeBtnIndex == '11-2') &&
              showFatherPage)
          "
        >
          <span class="demonstration">时间：</span>
          <el-date-picker
            style="width: 130px"
            v-model="time1"
            type="date"
            placeholder="开始时间"
          >
          </el-date-picker>
          <span style="margin: 0 5px"> 至 </span>
          <el-date-picker
            style="width: 130px"
            v-model="time2"
            type="date"
            placeholder="结束时间"
          >
          </el-date-picker>
        </div>
        <div
          class="_Select select-name"
          v-if="
            ((typeBtnIndex == '6-1' ||
              (typeBtnIndex == '6-2' && !checkedAppoint) ||
              typeBtnIndex == '7' ||
              typeBtnIndex == '8') &&
              !isManage) ||
            ((typeBtnIndex == '11-1' || typeBtnIndex == '11-2') &&
              showFatherPage)
          "
          :class="[
            typeBtnIndex == '11-1' || typeBtnIndex == '11-2'
              ? 'marginClass'
              : null,
          ]"
        >
          <span>人员：</span>
          <el-input
            class="input-name"
            type="text"
            v-model="userName"
            placeholder="请按名称查询"
            @input="searchPersons"
          />
          <el-select
            ref="selectRef"
            v-model="persons"
            :loading="loading"
            loading-text="加载中..."
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in personList"
              :key="item.id"
              :label="item.userName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <el-checkbox
          style="margin: 15px"
          v-model="checkedAppoint"
          v-if="(typeBtnIndex == '6-1' || typeBtnIndex == '6-2') && !isManage"
        >
          指派我的</el-checkbox
        >

        <div
          class="_Select"
          v-if="(typeBtnIndex == '2' || typeBtnIndex == '3') && !isManage"
        >
          <span>季度：</span>
          <el-select v-model="quarter" placeholder="请选择" clearable>
            <el-option
              v-for="(item, index) in ['第一季', '第二季', '第三季', '第四季']"
              :key="index"
              :label="item"
              :value="index + 1"
            >
            </el-option>
          </el-select>
        </div>
        <el-button
          v-if="
            showFatherPage && (typeBtnIndex == '11-1' || typeBtnIndex == '11-2')
          "
          style="margin-top: 10px"
          type="success"
          @click="getTableList()"
          >搜索</el-button
        >
        <el-button
          v-if="
            !showFatherPage && typeBtnIndex != '11-1' && typeBtnIndex != '11-2'
          "
          style="margin-left: 10px"
          type="success"
          @click="getIauditSonData(dept)"
          >搜索</el-button
        >
        <el-button
          v-if="!showFatherPage"
          @click="toFatherPage"
          icon="el-icon-back"
          >返回</el-button
        >
      </div>

      <div class="flex-box" v-if="typeBtnIndex == '4' && !isManage">
        <div class="_Select">
          <span>文件名称：</span>
          <el-input
            style="width: 180px"
            placeholder="请输入文件名称"
            v-model="fileName"
          />
        </div>
        <div class="_Select">
          <span>文件编号：</span>
          <el-input
            style="width: 180px"
            placeholder="请输入文件编号"
            v-model="fileNo"
          />
        </div>
      </div>
      <el-button
        v-if="
          showFatherPage && typeBtnIndex != '11-1' && typeBtnIndex != '11-2'
        "
        style="margin-left: 10px"
        type="success"
        @click="
          !isManage
            ? getTableList()
            : isThreeTable
            ? getTwoTable(areaAuditItem)
            : getManage()
        "
        >搜索</el-button
      >
      <el-button
        @click="backPage"
        icon="el-icon-back"
        style="margin: 10px; margin-left: 17px"
        v-if="isTwoTable == true || isThreeTable == true"
        >返回</el-button
      >
      <el-checkbox
        style="margin: 15px"
        v-model="checked"
        v-if="
          searchPage &&
          typeBtnIndex != '6-1' &&
          typeBtnIndex != '6-2' &&
          typeBtnIndex != '7' &&
          typeBtnIndex != 4 &&
          typeBtnIndex != '8' &&
          isManage == false &&
          typeBtnIndex != '10' &&
          typeBtnIndex != '11-1' &&
          typeBtnIndex != '11-2'
        "
        @change="changQueChecked"
      >
        {{
          typeBtnIndex == 'ZC' || typeBtnIndex == 'ZJ'
            ? typeBtnIndex == 'ZC'
              ? '只看未完成整改'
              : '只看需整改数'
            : '只看问题点数'
        }}</el-checkbox
      >
      <!-- noCompyDataback 按钮回到第一层没公司时的数据，只提供那些可以筛选公司的列表使用-->
      <el-button
        v-if="
          noCompyDataback && typeBtnIndex != '11-1' && typeBtnIndex != '11-2'
        "
        @click="noCompyGetData"
        icon="el-icon-back"
        >返回</el-button
      >
    </div>

    <el-button
      v-if="!searchPage"
      style="margin-bottom: 10px"
      @click="fileToBack"
      icon="el-icon-back"
      >返回</el-button
    >
    <!-- 年度/季度/内审/考核细则最外统计层 -->
    <div
      v-if="
        typeBtnIndex != '4' &&
        typeBtnIndex != '6-1' &&
        typeBtnIndex != '6-2' &&
        typeBtnIndex != '7' &&
        typeBtnIndex != '8' &&
        typeBtnIndex != '11-1' &&
        typeBtnIndex != '11-2' &&
        isManage == false &&
        showFatherPage
      "
    >
      <el-table
        id="table"
        :data="tableData.slice(start, end)"
        :height="elementHeight"
        style="width: 100%"
        @row-dblclick="toSonPage"
        @row-click="rowClick"
        :row-style="selectedstyle"
        :row-class-name="tableRowClassName"
        :header-cell-style="{
          background: '#EDF0F7',
          color: '#000000',
        }"
      >
        <el-table-column type="index" label="序号" width="80">
        </el-table-column>
        <el-table-column prop="companyName" label="所属公司" width="250">
        </el-table-column>
        <el-table-column
          v-if="typeBtnIndex != '0' && typeBtnIndex != '10'"
          :label="!this.compy ? '部门个数' : '部门名称'"
          prop="deptName"
          width="150"
        >
        </el-table-column>
        <el-table-column
          v-if="typeBtnIndex == '0' && noCompyDataback"
          label="部门名称"
          prop="deptName"
          width="150"
        >
        </el-table-column>
        <el-table-column
          v-if="!noCompyDataback && typeBtnIndex == '0'"
          label="考试部门数"
          prop="examDeptCount"
          width="150"
        >
        </el-table-column>
        <el-table-column
          v-if="!noCompyDataback && typeBtnIndex == '0'"
          label="审核部门数"
          prop="auditDeptCount"
          width="150"
        >
        </el-table-column>
        <el-table-column v-if="showAvgScore" label="考试情况" prop="avgScore">
          <template slot-scope="scope">
            <span style="color: blue" @click="getAvgScore(scope.row)">{{
              scope.row.avgScore
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="typeBtnIndex != 'ZC' && typeBtnIndex != 'ZJ' && typeBtnIndex != '10'"
          label="岗位个数"
          prop="postCount"
        >
        </el-table-column>
        <el-table-column
          v-if="typeBtnIndex == 'ZC' || typeBtnIndex == 'ZJ'"
          label="管理过程个数"
          prop="functionCount"
        >
        </el-table-column>
        <el-table-column
          label="抽取问题数"
          prop="titleCount"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="passCount"
          label="通过数量"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="failCount"
          label="问题点数"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
    </div>
    <!-- 年度 -->
    <el-table
      id="table"
      v-if="
        (typeBtnIndex == '0' || typeBtnIndex == '1' || typeBtnIndex == '10') &&
        !showFatherPage
      "
      :data="tableData.slice(start, end)"
      :height="elementHeight"
      style="width: 100%"
      @row-dblclick="rowDblclick1"
      @row-click="rowClick"
      :row-style="selectedstyle"
      :row-class-name="tableRowClassName"
      :header-cell-style="{
        background: '#EDF0F7',
        color: '#000000',
      }"
    >
      <el-table-column type="index" label="序号" width="55"> </el-table-column>
      <el-table-column prop="companyName" label="所属公司" width="250">
      </el-table-column>
      <el-table-column label="部门" prop="deptName" width="150" v-if="typeBtnIndex != '10'">
      </el-table-column>
      <el-table-column label="岗位" prop="postName" v-if="typeBtnIndex != '10'"> </el-table-column>
      <el-table-column
        label="抽取问题数"
        prop="titleCount"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="failCount" label="问题点数" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="status" label="检查状态" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-show="scope.row.status === 0">未抽题</span>
          <span v-show="scope.row.status === 1">已抽题</span>
          <span v-show="scope.row.status === 2">完成</span>
          <span v-show="scope.row.status === 3">整改完成</span>
          <span v-show="scope.row.status === 4">未完成</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="completeTime"
        label="完成时间"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.completeTime | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveTime" label="创建时间" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.saveTime | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveUserName" label="创建人" show-overflow-tooltip>
      </el-table-column>
    </el-table>
    <!-- 季度 -->
    <el-table
      :data="tableData.slice(start, end)"
      id="table"
      v-if="(typeBtnIndex == '2' || typeBtnIndex == '3') && !showFatherPage"
      tooltip-effect="dark"
      style="width: 100%"
      :height="elementHeight"
      @row-dblclick="rowDblclick2"
      @row-click="rowClick"
      :row-style="selectedstyle"
      :row-class-name="tableRowClassName"
      :header-cell-style="{
        background: '#EDF0F7',
        color: '#000000',
      }"
    >
      <el-table-column type="index" label="序号" width="80"> </el-table-column>
      <el-table-column prop="companyName" label="所属公司" width="200">
      </el-table-column>
      <el-table-column label="部门" prop="deptName" width="120">
      </el-table-column>
      <el-table-column label="岗位" prop="postName" width="120">
      </el-table-column>
      <el-table-column
        label="抽取问题数"
        prop="titleCount"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="failCount" label="问题点数" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="status" label="检查状态" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-show="scope.row.status === 0">未抽题</span>
          <span v-show="scope.row.status === 1">已抽题</span>
          <span v-show="scope.row.status === 2">完成</span>
          <span v-show="scope.row.status === 3">整改完成</span>
          <span v-show="scope.row.status === 4">未完成</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="completeTime"
        label="完成时间"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.completeTime | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveTime" label="创建时间" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.saveTime | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveUserName" label="创建人" show-overflow-tooltip>
      </el-table-column>
    </el-table>
    <!-- 自查自纠 -->
    <el-table
      v-if="(typeBtnIndex == 'ZC' || typeBtnIndex == 'ZJ') && !showFatherPage"
      id="table"
      :data="tableData.slice(start, end)"
      style="width: 100%"
      :height="elementHeight"
      @row-dblclick="rowDblclickZCZJ"
      @row-click="rowClick"
      :row-style="selectedstyle"
      :row-class-name="tableRowClassName"
      :header-cell-style="{
        background: '#EDF0F7',
        color: '#000000',
      }"
      border
    >
      <el-table-column type="index" label="序号" width="80"> </el-table-column>
      <el-table-column prop="companyName" label="公司" width="250">
      </el-table-column>
      <el-table-column prop="deptName" label="检查部门"> </el-table-column>
      <!-- //过程管理参数未对，最外层加过程管理个数 -->
      <el-table-column prop="functionName" label="过程管理"> </el-table-column>
      <el-table-column label="类型" prop="auditType" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.auditType == 0">内审</span>
          <span v-if="scope.row.auditType == 1">外审</span>
        </template>
      </el-table-column>
      <el-table-column label="自查自纠" prop="checkStatus" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.checkStatus == 0">自查</span>
          <span v-if="scope.row.checkStatus == 1">自纠</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="failCount"
        :label="typeBtnIndex == 'ZC' ? '未完成整改' : '需整改数'"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="saveTime" label="创建时间" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.saveTime | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveUserName" label="创建人" show-overflow-tooltip>
      </el-table-column>
    </el-table>
    <!-- 文件履历 -->
    <div v-if="typeBtnIndex == '4' && isManage == false">
      <el-table
        v-if="searchPage"
        id="table"
        :data="tableData.slice(start, end)"
        style="width: 100%"
        :height="elementHeight"
        @row-dblclick="rowDblclick3"
        @row-click="rowClick"
        :row-style="selectedstyle"
        :row-class-name="tableRowClassName"
        :header-cell-style="{
          background: '#EDF0F7',
          color: '#000000',
        }"
        border
        class="fileTable"
      >
        <el-table-column type="index" label="序号" width="100">
        </el-table-column>
        <el-table-column prop="fileNo" label="文件编号" width="200">
        </el-table-column>
        <el-table-column prop="fileName" label="文件名称"> </el-table-column>
      </el-table>
      <el-table
        :data="tableData.slice(start, end)"
        id="table"
        v-else
        style="width: 100%"
        :height="elementHeight"
        @row-click="rowClick"
        :row-style="selectedstyle"
        :row-class-name="tableRowClassName"
        :header-cell-style="{
          background: '#EDF0F7',
          color: '#000000',
        }"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column type="index" label="序号" width="55">
        </el-table-column>
        <el-table-column label="文件名称" prop="fileNames" width="260">
        </el-table-column>
        <el-table-column label="保存时间" prop="saveTime" width="200">
        </el-table-column>
        <el-table-column prop="saveMan" label="保存人" width="120">
        </el-table-column>
        <el-table-column prop="url" label="预览地址">
          <template slot-scope="scope">
            <a
              :href="scope.row.url"
              target="_blank"
              style="text-decoration: none"
              >{{ scope.row.url }}</a
            ></template
          >
        </el-table-column>
      </el-table>
    </div>
    <!-- //现场管理/7s管理 -->
    <div v-if="typeBtnIndex == '5'">
      <el-table
        id="table"
        v-if="searchPage"
        :data="tableData.slice(start, end)"
        style="width: 100%"
        :height="
          isTwoTable || isThreeTable ? elementHeight : elementHeight + 28
        "
        @row-dblclick="getAreaAudit"
        @row-click="rowClick"
        :row-style="selectedstyle"
        :row-class-name="tableRowClassName"
        :header-cell-style="{
          background: '#EDF0F7',
          color: '#000000',
        }"
        border
        class="fileTable"
      >
        <el-table-column type="index" width="120px" label="序号">
        </el-table-column>
        <el-table-column
          :prop="isThreeTable === false ? 'companyName' : 'compName'"
          label="公司名称"
          :width="isThreeTable ? 250 : 300"
        >
        </el-table-column>
        <el-table-column
          :prop="isThreeTable === false ? 'areaName' : 'auditAreaName'"
          :label="isTwoTable === false ? '区域个数' : '区域名称'"
        >
        </el-table-column>
        <!-- <el-table-column prop="areaNo" label="区域编号"> </el-table-column> -->
        <el-table-column prop="failCount" label="问题点"> </el-table-column>
        <el-table-column prop="titleCount" label="抽题总数"> </el-table-column>
        <el-table-column prop="notCount" label="不适用数"> </el-table-column>
        <el-table-column
          prop="saveTime"
          label="创建时间"
          v-if="isThreeTable === true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.saveTime | time }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="saveUserName"
          label="创建人"
          v-if="isThreeTable === true"
        >
        </el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          v-if="isThreeTable === true"
        >
        </el-table-column>
        <el-table-column prop="score" label="分数" v-if="isThreeTable === true">
        </el-table-column>
      </el-table>
    </div>
    <!-- 专题指派，学习指派，专题学习档案 -->
    <div
      v-if="
        typeBtnIndex == '6-1' || typeBtnIndex == '6-2' || typeBtnIndex == '7'
      "
    >
      <el-table
        :data="tableData.slice(start, end)"
        style="width: 100%"
        :height="elementHeight"
        :header-cell-style="{
          background: '#EDF0F7',
          color: '#000000',
        }"
      >
        <el-table-column type="index" width="80px" label="序号"></el-table-column>
        <el-table-column
          :prop="typeBtnIndex != '7' ? 'compName' : 'companyName'"
          :width="typeBtnIndex != '7' ? '230' : '300'"
          label="公司"
        >
        </el-table-column>
        <el-table-column prop="deptName" width="200" label="部门">
        </el-table-column>
        <el-table-column
          :prop="typeBtnIndex != '7' ? 'studyUserName' : 'studyUser'"
          :label="typeBtnIndex != '7' ? '考核人员' : '学习人员'"
          width="130"
        >
        </el-table-column>
        <el-table-column
          prop="postName"
          width="200"
          :label="typeBtnIndex == '6-2' ? '学习岗位' : '专题岗位'"
        >
        </el-table-column>
        <el-table-column
          v-if="typeBtnIndex == '6-1'"
          prop="theme"
          width="200"
          label="专题名称"
        >
        </el-table-column>
        <el-table-column
          prop="isTest"
          label="任务类型"
          v-if="typeBtnIndex != 7"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.isTest === 0" type="danger">指派学习</span>
            <span v-if="scope.row.isTest === 1" type="danger">指派考试</span>
            <span v-if="scope.row.isTest === 2" type="danger">专题学习</span>
            <span v-if="scope.row.isTest === 3" type="danger">专题考试</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="assignUserName"
          label="发布人"
          v-if="typeBtnIndex != '7'"
        >
        </el-table-column>
        <el-table-column label="任务状态">
          <template slot-scope="scope">
            <div>
              <span
                v-if="
                  typeBtnIndex != '7'
                    ? scope.row.status == 0
                    : scope.row.isComplete == 0
                "
                type="danger"
                >未完成</span
              >
              <span v-else type="success">已完成</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="保存时间" v-if="typeBtnIndex == '7'">
          <template slot-scope="scope">
            <span>{{ scope.row.saveTime | time }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 专题学习档案 -->
    <div v-if="typeBtnIndex == '8'">
      <el-table
        :data="tableData.slice(start, end)"
        style="width: 100%"
        :height="elementHeight"
        :header-cell-style="{
          background: '#EDF0F7',
          color: '#000000',
        }"
      >
        <el-table-column type="index" label="序号" width="100">
        </el-table-column>
        <el-table-column prop="themesName" label="专题名称"> </el-table-column>
        <el-table-column prop="studyUser" label="学习人员"> </el-table-column>
        <el-table-column prop="year" label="年份"> </el-table-column>
        <el-table-column prop="month" label="月份"> </el-table-column>
        <el-table-column prop="saveTime" label="保存时间">
          <template slot-scope="scope">
            <span>{{ scope.row.saveTime | time }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 考试信息最外层 -->
    <div
      v-if="
        (typeBtnIndex == '11-1' || typeBtnIndex == '11-2') && showFatherPage
      "
    >
      <el-table
        :data="tableData.slice(start, end)"
        style="width: 100%"
        :height="elementHeight - 20"
        :header-cell-style="{
          background: '#EDF0F7',
          color: '#000000',
        }"
        @row-dblclick="toSonPage"
      >
        <el-table-column type="index" label="序号" width="100">
        </el-table-column>
        <el-table-column prop="compName" label="公司"> </el-table-column>
        <el-table-column prop="deptName" label="部门"> </el-table-column>
        <el-table-column prop="postName" label="岗位"> </el-table-column>
        <el-table-column prop="examUserName" label="考试人员" width="120">
        </el-table-column>
        <el-table-column prop="examTime" label="考试时间" width="120">
        </el-table-column>
        <el-table-column prop="saveTime" label="保存时间" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.saveTime | time }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.status == 2 ? '已完成' : '未完成' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="examScore" label="得分" width="120">
        </el-table-column>
      </el-table>
    </div>
    <!-- 考核信息子页 -->
    <div
      v-if="
        (typeBtnIndex == '11-1' || typeBtnIndex == '11-2') && !showFatherPage
      "
    >
      <el-table
        :data="tableData.slice(start, end)"
        style="width: 100%"
        :height="elementHeight + 30"
        :header-cell-style="{
          background: '#EDF0F7',
          color: '#000000',
        }"
        @row-dblclick="toTextSonPage"
      >
        <el-table-column type="index" label="序号" width="100">
        </el-table-column>
        <el-table-column prop="question" label="问题"> </el-table-column>
        <el-table-column prop="selectOption" label="答案选项" width="280">
        </el-table-column>
        <el-table-column prop="answer" label="选择答案" width="100">
        </el-table-column>
        <el-table-column prop="quesAnswer" label="正确答案" width="100">
        </el-table-column>
        <el-table-column prop="titleStatus" label="答题状态" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.isSkip == 1">跳过</span>
            <div v-else>
              <span v-show="scope.row.titleStatus == 0">对</span>
              <span v-show="scope.row.titleStatus == 1">错</span>
              <span v-show="scope.row.titleStatus == 2">没做</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="isSelect" label="题目类型" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.isSelect == 1 ? '选择题' : '判断题' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" width="150">
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-sizes="[10, 20, 50]"
      :page-size="curSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.length"
      style="text-align: end; margin-top: 10px; margin-right: 10px"
    >
    </el-pagination>
    <el-dialog
      title="考试情况"
      :visible.sync="dialogAvgScore"
      width="50%"
      top="5vh"
      :show-close="true"
      :before-close="handleScoreClose"
    >
      <el-table
        :data="avgScoreData"
        style="width: 100%"
        :height="elementHeight"
        :header-cell-style="{
          background: '#EDF0F7',
          color: '#000000',
        }"
        :span-method="objectSpanMethod3"
        border
      >
        <el-table-column prop="postName" label="岗位名称"> </el-table-column>
        <el-table-column prop="studyUserName" label="学习人员">
        </el-table-column>
        <el-table-column prop="score" label="分数"> </el-table-column>
        <el-table-column prop="avgScore" label="平均分"> </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange2"
        @current-change="handleCurrentChange2"
        :current-page.sync="currentPage2"
        :page-sizes="[10, 20, 50]"
        :page-size="curSize2"
        layout="total, sizes, prev, pager, next, jumper"
        :total="avgScoreCount"
        style="
          position: relative;
          text-align: center;
          padding-top: 10px;
          width: 100%;
          right: 0;
        "
      >
      </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import { get } from '../../api/http'
import axios from 'axios'
import { objectSpanMethod3 } from '../../modules/TableConflate'
export default {
  data() {
    return {
      elementHeight: 0,
      typeBtnIndex: '',
      tableData: [],
      tableData2: [], //用于切换文件履历数据
      start: 0,
      end: 10,
      curSize: 10,
      currentPage: 1,
      tableRowIndex: null,
      tableRowIndex1: null, //存储年度季度内审最外层数据页面切换
      curSize1: 10, //存储年度季度内审最外层数据页面切换
      currentPage1: 1,
      searchPage: true,
      compy: '',
      dept: '',
      post: '',
      deptList: [],
      postList: [],
      showLoading: false,
      fileName: '',
      fileNo: '',
      year: new Date().getFullYear() + '',
      tableData3: [], //用于切换问题点数
      tableData4: [], //存储年度季度内审最外层数据
      auditType: '内审',
      showFatherPage: true,
      quarter: '',
      twotable: [],
      isManage: false,
      isTwoTable: false,
      isThreeTable: false,
      persons: '',
      userName: '',
      personList: [],
      loading: false,
      time1: '',
      time2: '',
      checkedAppoint: false,
      areaAuditItem: {},
      dialogAvgScore: false,
      showAvgScore: false,
      avgScoreData: [],
      curSize2: 10,
      currentPage2: 1,
      rowCompny: '',
      rowDeptId: '',
      noCompyDataback: false,
    }
  },
  filters: {
    time: function (e) {
      if (!e) {
        return ''
      }
      return e.split('T')[0]
    },
  },
  beforeRouteEnter(to, from, next) {
    if (
      from.name != 'AuditChildPage' &&
      from.name != 'QuestionDetail' &&
      from.name != 'RulesDetail'
    ) {
      to.meta.ifDoFresh = true
    }
    next()
  },
  activated() {
    if (this.$route.meta.ifDoFresh) {
      this.$route.meta.ifDoFresh = false
      this.tableRowIndex = null
      if (this.$route.query.fromQuery) {
        this.typeBtnIndex = this.$route.query.fromQuery
      } else {
        this.typeBtnIndex = '0'
      }
      this.changeTypeBtn(this.typeBtnIndex)
      this.getElementHeight()
    }else{
      this.showLoading = false
      this.changeTypeBtn(this.typeBtnIndex)
    }
  },
  methods: {
    getElementHeight() {
      //innerHeight浏览器窗口的高度
      //offsetTop：距离上层控件的位置
      //最外层嵌套页面(顶部菜单)60,底(分页组件)52,div(main)的滚动条距顶部70
      console.log(document.getElementById('table'))
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 60 + 62)
      })
    },
    changCompy(e) {
      this.dept = ''
      this.post = ''
      if (!e) return
      get('/api/Department/GetNoTree?CompId=' + e).then((res) => {
        this.deptList = res.data
      })
    },
    changDept(e) {
      this.post = ''
      if (!e) return
      get('/api/Post?CompId=' + this.compy + '&DeptId=' + e).then((res) => {
        this.postList = res.data
      })
    },
    searchPersons() {
      if (!this.userName) {
        this.persons = ''
        return
      }
      this.$refs.selectRef.toggleMenu()
      this.loading = true
      get('/api/User?UserNo=' + '&UserName=' + this.userName).then((resp) => {
        if (resp.code == 200) {
          this.personList = resp.data
          this.loading = false
        }
      })
    },
    backPage() {
      if (this.isThreeTable) {
        this.tableData = this.twotable
        this.isThreeTable = false
        this.isTwoTable = true
        this.start = 0
        this.end = 10
        this.currentPage = 1
      } else {
        this.isTwoTable = false
        this.areaAuditItem = {}
        this.getManage()
      }
    },
    changeTypeBtn(index) {
      this.showFatherPage = true
      this.typeBtnIndex = index
      this.compy = ''
      this.dept = ''
      this.post = ''
      this.fileName = ''
      this.persons = ''
      this.userName = ''
      this.time1 = ''
      this.time2 = ''
      if (index == '9') {
        this.$router.push('./monitor-record')
        return
      }
      if (index == '5') {
        this.isManage = true
        this.getManage()
        this.isThreeTable = false
        this.isTwoTable = false
        return
      } else {
        this.isManage = false
      }
      this.getTableList()
    },
    // 分页
    handleSizeChange(e) {
      this.curSize = e
      this.start = (this.currentPage - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    handleCurrentChange(e) {
      this.tableRowIndex = null
      this.start = (e - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    // 获取列表
    initialize() {
      this.showLoading = true
      this.tableRowIndex = null
      this.currentPage = 1
      this.start = 0
      this.end = 10
      this.currentPage = 1
      this.tableData = []
      this.noCompyDataback = false
    },
    getAreaAudit(e) {
      this.areaAuditItem = e
      this.initialize()
      if (this.isThreeTable) {
        this.$router.push(
          '/audit-childpage?AuditId=' + e.id + '&show7STable=true'
        )
        return
      }
      if (this.isTwoTable) {
        this.getTwoTable(e)
        return
      }
      get(
        '/api/AreaAudit/GetAreaAuditCensus?CompId=' +
          e.companyId +
          '&AuditType=0&Years=' +
          this.year
      ).then((res) => {
        this.tableData = res.data
        this.twotable = res.data
        this.isTwoTable = true
        this.showLoading = false
      })
    },
    getTwoTable(e) {
      this.initialize()
      get(
        '/api/AreaAudit/GetByNewAreaAudit?CompId=' +
          e.companyId +
          '&AreaName=' +
          e.areaName +
          '&AuditType=0&Years=' +
          this.year
      ).then((res) => {
        this.isThreeTable = true
        this.tableData = res.data
        this.showLoading = false
      })
    },
    getManage() {
      this.initialize()
      get(
        '/api/AreaAudit/GetAreaAuditCensus?CompId=' +
          (this.areaAuditItem.companyId ? this.areaAuditItem.companyId : '') +
          '&AuditType=0&Years=' +
          this.year
      ).then((res) => {
        this.tableData = res.data
        this.showLoading = false
      })
    },
    noCompyGetData() {
      this.compy = ''
      this.getTableList()
    },
    getTableList() {
      this.initialize()
      var url = ''
      if (this.typeBtnIndex == 'ZC' || this.typeBtnIndex == 'ZJ') {
        if (this.compy) {
          this.noCompyDataback = true
        }
        //自查自纠
        url =
          '/api/InOutAudit/GetInOutAuditCensus?CompId=' +
          this.compy +
          '&AuditType=' +
          (this.auditType == '内审' ? 0 : 1) +
          '&Status=&Years=' +
          this.year +
          '&Quarter=&CheckStatus=' +
          (this.typeBtnIndex == 'ZC' ? 0 : 1)
        this.getTableData(url)
      } else if (
        this.typeBtnIndex == '6-1' ||
        this.typeBtnIndex == '6-2' ||
        this.typeBtnIndex == '7' ||
        this.typeBtnIndex == '8'
      ) {
        var userId = JSON.parse(sessionStorage.getItem('userInfo')).id
        if (this.typeBtnIndex == '6-1' || this.typeBtnIndex == '6-2') {
          url =
            '/api/AssignStudy/GetAssignRecord?FromDate=' +
            (this.time1 ? new Date(this.time1).toLocaleString() : '') +
            '&ToDate=' +
            (this.time2 ? new Date(this.time2).toLocaleString() : '') +
            '&UserId=' +
            (this.checkedAppoint ? userId : this.persons)
        } else if (this.typeBtnIndex == '7') {
          url = '/api/Studylist/GetRecord'
        } else {
          url = '/api/ThemesStudyList/GetRecord'
        }
        if (this.typeBtnIndex != '6-1' && this.typeBtnIndex != '6-2') {
          if (this.persons) {
            this.personList.forEach((i) => {
              if (i.id == this.persons) {
                url = url + '?UserNo=' + i.userNo
              }
            })
          }

          if (this.time1 && this.time2) {
            url =
              url +
              (this.persons ? '&' : '?') +
              'Starttime=' +
              new Date(this.time1).toLocaleString()
            url = url + '&Finishtime=' + new Date(this.time2).toLocaleString()
          }
        }
        this.getTableData(url)
      } else if (this.typeBtnIndex == '10') {
        url = '/api/NewExamineRules/GetExamineRulesCensus?Years=' + this.year
        this.getTableData(url)
      } else if (this.typeBtnIndex == '11-1' || this.typeBtnIndex == '11-2') {
        url =
          this.typeBtnIndex == '11-1'
            ? '/api/AssignExamPlan/GetAssignExamMsg'
            : '/api/AssignAuditExamPlan/GetAssignAuditExamMsg'
        this.getTableData(
          url +
            '?CompId=' +
            this.compy +
            '&DeptId=' +
            this.dept +
            '&PostId=' +
            this.post +
            '&ExamUserId=' +
            this.persons +
            '&DateFrom=' +
            (this.time1 ? new Date(this.time1).toLocaleString() : '') +
            '&DateTo=' +
            (this.time2 ? new Date(this.time2).toLocaleString() : '')
        )
      } else if (this.typeBtnIndex != '4') {
        if (this.compy) {
          this.noCompyDataback = true
        }
        //AuditType/typeBtnIndex：0-股份公司年度内审 1-子公司年度内审 2-股份公司季度内审 3-子公司季度内审
        //季度审核才有季度选项，(1、2、3、4代表四个季度)
        url =
          '/api/MixAuditHead/GetMixFileAuditCensus?CompId=' +
          this.compy +
          '&AuditType=' +
          this.typeBtnIndex +
          '&Years=' +
          this.year +
          (this.typeBtnIndex == '2' || this.typeBtnIndex == '3'
            ? '&Quarter=' + this.quarter
            : '')
        this.showFatherPage = true
        this.getTableData(url)
      } else {
        //文件履历
        this.searchPage = true
        this.getSearchFilePage()
      }
    },
    getTableData(url) {
      this.checked = false
      get(url)
        .then((resp) => {
          if (resp.code == 200) {
            if (this.typeBtnIndex == '6-1') {
              resp.data.map((i) => {
                if (i.isTest == 2 || i.isTest == 3) {
                  this.tableData.push(i)
                }
              })
            } else if (this.typeBtnIndex == '6-2') {
              resp.data.map((i) => {
                if (i.isTest == 0 || i.isTest == 1) {
                  this.tableData.push(i)
                }
              })
            } else if(this.typeBtnIndex == '12'){
              this.tableData = [
                {
                  auditDeptCount:1,
                  avgScore:'',
                  companyId:0,
                  companyName: "合计：1",
                  deptId:0,
                  deptName:'1',
                  examDeptCount:1,
                  failCount:0,
                  passCount:0,
                  postCount:3,
                  titleCount:86,
                },
                {
                  auditDeptCount:1,
                  avgScore:'',
                  companyId:1,
                  companyName: "佛山水务环保股份有限公司",
                  deptId:0,
                  deptName:'1',
                  examDeptCount:1,
                  failCount:0,
                  passCount:0,
                  postCount:3,
                  titleCount:86,
                }
              ]
            } else {
              if (this.typeBtnIndex == 0 && resp.data.length > 0) {
                this.showAvgScore = isNaN(resp.data[0].deptName * 1)
                  ? true
                  : false //判断用岗位乘1是否是NAN，是则为岗位名称，显示考试情况
              }
              this.tableData = resp.data
            }
            this.tableData3 = JSON.parse(JSON.stringify(this.tableData))
          }
          this.showLoading = false
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: '系统异常，请稍后再试',
          })
          this.showLoading = false
        })
    },
    getSearchFilePage() {
      get(
        '/api/QuestionPool/GetQuestionByFile?CompId=' +
          this.compy +
          '&DeptId=' +
          this.dept +
          '&FileNo=' +
          this.fileNo +
          '&FileName=' +
          this.fileName
      )
        .then((resp) => {
          if (resp.code == 200) {
            this.tableData = resp.data
            this.showLoading = false
          } else {
            this.$message.error(resp.data.Message)
          }
        })
        .catch(() => {
          this.$message.error('系统异常，请稍后再试')
        })
    },
    getFilePDF(fileNo) {
      this.initialize()
      axios('http://10.0.0.127:81/Findiso.ashx?cmd=findhis&fileno=' + fileNo)
        .then((resp) => {
          this.showLoading = false
          if (resp.data.StatusCode == 200) {
            this.tableData = resp.data.Data
          } else {
            this.$message.error(resp.data.Message)
          }
        })
        .catch(() => {
          this.showLoading = false
          this.$message.error('系统异常，请稍后再试')
        })
    },
    // 点击table
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          'background-color': '#E2E8FA !important',
          'font-weight': 'bold !important',
          color: '#555555',
        }
      }
    },
    changQueChecked() {
      this.tableData = []
      if (this.checked) {
        this.tableData3.map((i) => {
          if (i.failCount != 0) {
            this.tableData.push(i)
          }
        })
      } else {
        this.tableData = JSON.parse(JSON.stringify(this.tableData3))
      }
    },
    toSonPage(row) {
      this.tableData4 = JSON.parse(JSON.stringify(this.tableData))
      this.curSize1 = this.curSize
      this.currentPage1 = this.currentPage
      this.currentPage = 1
      this.curSize = 10
      this.tableRowIndex1 = this.tableRowIndex
      if (this.typeBtnIndex == '11-1' || this.typeBtnIndex == '11-2') {
        this.getIauditSonData(this.dept, row.assignId,row.id)
        return
      }
      if (!this.compy && this.typeBtnIndex != '10') {
        //没选公司时双击，依旧显示最外层并根据公司查询所有部门，
        this.compy = row.companyId * 1
        this.changCompy(row.companyId)
        this.getTableList()
        return
      }
      if (this.typeBtnIndex == '10') {
        this.compy = row.companyId
      }
      this.getIauditSonData(row.deptId)
      this.changDept(row.deptId)
    },
    // 考试信息子页
    toTextSonPage(row){
      this.curSize1 = this.curSize
      this.currentPage1 = this.currentPage
      this.currentPage = 1
      this.curSize = 10
      this.tableRowIndex1 = this.tableRowIndex
      if (this.typeBtnIndex == '11-1' || this.typeBtnIndex == '11-2') {
        if(row.assignId){
          this.tableData4 = JSON.parse(JSON.stringify(this.tableData))
          this.getIauditSonData(this.dept, row.assignId,row.id)
        }
        return
      }
      if (!this.compy && this.typeBtnIndex != '10') {
        //没选公司时双击，依旧显示最外层并根据公司查询所有部门，
        this.compy = row.companyId * 1
        this.changCompy(row.companyId)
        this.getTableList()
        return
      }
      if (this.typeBtnIndex == '10') {
        this.compy = row.companyId
      }
      this.getIauditSonData(row.deptId)
      this.changDept(row.deptId)
    },
    getIauditSonData(dept, assignId,id) {
      this.initialize()
      this.dept = dept
      var url = ''
      if (this.typeBtnIndex == '10') {
        url =
          '/api/NewExamineRules/GetByNewExamineRules?CompId=' +
          this.compy +
          '&Years=' +
          this.year
      } else if (this.typeBtnIndex == '11-1' || this.typeBtnIndex == '11-2') {
        // 考试信息
        url =
          (this.typeBtnIndex == '11-1'
            ? '/api/AssignExamPlanList/GetByAssignId?AssignId=' + assignId
            : '/api/AssignAuditExamPlanList/GetByExPlanId?ExPlanId=' + id) 
      } else if (this.typeBtnIndex != 'ZC' && this.typeBtnIndex != 'ZJ') {
        //年度季度审核
        url =
          '/api/MixAuditHead/GetByNewMixFileAudit?CompId=' +
          this.compy +
          '&DeptId=' +
          dept +
          '&PostId=' +
          this.post +
          '&AuditType=' +
          this.typeBtnIndex +
          '&Years=' +
          this.year +
          (this.typeBtnIndex == '2' || this.typeBtnIndex == '3'
            ? '&Quarter=' + this.quarter
            : '')
      } else {
        //内外审
        url =
          '/api/InOutAudit/GetByNewInOutAudit?CompId=' +
          this.compy +
          '&DeptId=' +
          (dept ? dept : '') +
          '&AuditType=' +
          (this.auditType == '内审' ? 0 : 1) +
          '&Status=&Years=' +
          Number(this.year) +
          '&Quarter=&CheckStatus=' +
          (this.typeBtnIndex == 'ZC' ? 0 : 1)
      }
      get(url)
        .then((resp) => {
          if (resp.code == 200) {
            this.tableData = resp.data
            this.checked = false
            this.showFatherPage = false
            this.tableData3 = JSON.parse(JSON.stringify(this.tableData))
            this.tableData = resp.data.map(i =>{
              if(i.selectOption){
                i.selectOption = i.selectOption.replace(/n\//g, " ")
              }
              return i;
            })
          }
          this.showLoading = false
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: '系统异常，请稍后再试',
          })
          this.showLoading = false
        })
    },
    toFatherPage() {
      if (this.compy && this.typeBtnIndex != '10') this.noCompyDataback = true
      this.showFatherPage = true
      this.tableData = JSON.parse(JSON.stringify(this.tableData4))
      this.curSize = this.curSize1
      this.currentPage = this.currentPage1
      this.tableRowIndex = this.tableRowIndex1
      this.handleSizeChange(this.curSize1)
      this.handleCurrentChange(this.currentPage1)
    },
    rowClick(row) {
      this.tableRowIndex = row.index
    },
    rowDblclick1(row) {
      if (this.typeBtnIndex == '10') {
        this.$router.push(
          '/RulesDetail?Id=' + row.id + '&fromArchivesManage=' + true + '&CpyID=' + row.companyId
        )
        return
      }
      this.$router.push('/audit-childpage?Id=' + row.id)
    },
    rowDblclick2(row) {
      this.$router.push('/audit-childpage?Id=' + row.id)
    },
    rowDblclick3(row) {
      this.searchPage = false
      this.tableData2 = JSON.parse(JSON.stringify(this.tableData))
      this.getFilePDF(row.fileNo)
    },
    rowDblclickZCZJ(row) {
      this.$router.push('/question-detail?disabled=true&InOutAuditId=' + row.id)
    },
    fileToBack() {
      this.searchPage = true
      this.tableData = this.tableData2
    },
    // 人员考试情况
    objectSpanMethod3({ rowIndex, columnIndex }) {
      return objectSpanMethod3(rowIndex, columnIndex, this.avgScoreData)
    },
    handleScoreClose() {
      this.dialogAvgScore = false
    },
    getAvgScore(row) {
      this.currentPage2 = 1
      this.rowCompny = row.companyId
      this.rowDeptId = row.deptId
      this.reqGetAvgScore()
    },
    reqGetAvgScore() {
      get(
        '/api/AuditTestReport/GetDetailPage?Years=' +
          this.year +
          '&CompId=' +
          this.rowCompny +
          '&DeptId=' +
          this.rowDeptId +
          '&limit=' +
          this.curSize2 +
          '&page=' +
          this.currentPage2
      ).then((resp) => {
        if (resp.code == 200) {
          this.dialogAvgScore = true
          this.avgScoreData = resp.data
          this.avgScoreCount = resp.count
        }
      })
    },
    handleSizeChange2(e) {
      this.curSize2 = e
      this.reqGetAvgScore()
    },
    handleCurrentChange2(e) {
      this.currentPage2 = e
      this.reqGetAvgScore()
    },
  },
}
</script>

<style lang="less" scoped>
.action {
  background: #5b79ae !important;
  color: #ffffff !important;
}

.type-box {
  display: flex;
  padding-bottom: 10px;
  align-items: center;
  .flex-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  /deep/.el-checkbox__inner {
    width: 20px !important;
    height: 20px !important;
  }

  /deep/.el-checkbox__inner::after {
    height: 11px !important;
    left: 7px !important;
  }
}

._Select {
  width: 260px !important;

  > span {
    width: 90px !important;
  }
}

.fileTable {
  /deep/.el-table__cell {
    text-align: start !important;
    padding-left: 10px !important;
  }
}

/deep/.el-table__row {
  cursor: pointer !important;
}

.type-box {
  /deep/ .el-menu {
    margin-left: 20px;
    background-color: transparent !important;
  }

  /deep/.el-menu--horizontal > .el-menu-item {
    border-top: 0 !important;
  }

  /deep/ .el-menu--horizontal > .el-submenu .el-submenu__title,
  /deep/.el-menu--horizontal > .el-menu-item {
    height: 40px !important;
    line-height: 40px !important;
    padding: 0 38px !important;
    font-size: 12px !important;
    background-color: #ffffff;
    box-shadow: 0px 2px 5px #d0d0d0;
    border-radius: 3px;
    margin-right: 25px;
  }

  /deep/.el-menu--horizontal > .el-menu-item.is-active {
    border-color: transparent !important;
  }

  ._Select > span {
    width: 80px !important;
  }
}
.select-name {
  width: 400px !important;
  margin-right: 10px;
  .el-select {
    width: 280px !important;
    /deep/.el-select__tags {
      overflow: hidden !important;
      flex-wrap: nowrap !important;
    }
    input {
      border: none;
      outline: none !important;
    }
  }
  .input-name {
    width: 180px !important;
    border: 1px solid #c0c0c0d5;
    height: 28px !important;
    line-height: 28px !important;
    font-size: 13px !important;
  }
  ::-webkit-input-placeholder {
    color: #c0c0c0fc;
  }
  :-moz-placeholder {
    color: #c0c0c0fc;
  }
  ::moz-placeholder {
    color: #c0c0c0fc;
  }
  :-ms-input-placeholder {
    color: #c0c0c0fc;
  }
}
.type-box {
  .select-time {
    display: flex;
    align-items: center;
    margin-left: 30px;
  }
  .el-range-editor.el-input__inner {
    padding: 0 10px !important;
  }
  /deep/ .el-range-separator {
    line-height: 28px !important;
  }
}
.marginClass {
  margin-top: 10px;
}
</style>
